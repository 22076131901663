import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Stack,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { styled } from '@mui/styles';
import Slider from '@mui/material/Slider';
import { motion } from 'framer-motion';

import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';

interface CardProps {
  icon: React.ReactElement;
  title: string;
  content?: React.ReactElement;
}

const InfoCard: React.FC<CardProps> = ({ icon, title, content }) => {
  return (
    <Card sx={{ height: '100%' }} elevation={4}>
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          {icon}
          <Typography sx={{ mt: 2 }} variant="h3" gutterBottom>
            {title}
          </Typography>
          {content && <Box sx={{ width: '100%' }}>{content}</Box>}
        </Box>
      </CardContent>
    </Card>
  );
};

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const DiinguSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 4,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '4px solid',
    borderColor: theme.palette.primary.main,
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-rail': {
    opacity: 0.3,
    backgroundColor: theme.palette.primary.main
  },
  '& .MuiSlider-mark': {
    backgroundColor: theme.palette.primary.main,
    height: 16,
    width: 4,
    borderRadius: 2,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor'
    }
  }
}));

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const LicensePricingBody: React.FC = () => {
  const theme = useTheme();
  const [licenseCount, setLicenseCount] = useState<number>(100);
  function getLicensePrice(amount: number): number {
    if (amount >= 300) return 0;
    if (amount >= 200) return 199;
    if (amount >= 100) return 209;
    if (amount >= 0) return 219;
    console.error('Die Mindestanzahl von Lizenzen sollte 25 sein.');
    return 0;
  }

  const totalPrice = getLicensePrice(licenseCount);

  const marks = [
    {
      value: 0,
      label: '35',
      scaledValue: 35
    },
    {
      value: 100,
      label: '50',
      scaledValue: 50
    },
    {
      value: 200,
      label: '100',
      scaledValue: 100
    },
    {
      value: 300,
      label: '300+',
      scaledValue: 300
    }
  ];

  const scale = (value: number) => {
    const previousMarkIndex = Math.floor(value / 100);
    const previousMark = marks[previousMarkIndex];
    const remainder = value % 100;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = marks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 100;
    return Math.floor(remainder * increment + previousMark.scaledValue);
  };

  const imageDir = require.context('assets/images');

  return (
    <Container sx={{ pb: '80px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h1" color="primary" gutterBottom sx={{ mb: 1 }}>
            Leistungsumfang und Preise
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography textAlign="center" color="primary" variant="h3">
                Diese Leistungen erhalten Sie:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard
                icon={
                  <SchoolRoundedIcon
                    color="primary"
                    sx={{ fontSize: '3rem' }}
                  />
                }
                title="Alle Fortbildungskurse"
                content={
                  <Box textAlign="start" sx={{ width: '100%' }}>
                    <List>
                      <ListItem sx={{ py: '4px' }}>
                        <ListItemIcon>
                          <TaskAltRoundedIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span
                              style={{
                                fontSize: '1.25rem',
                                lineHeight: '1.5rem'
                              }}
                            >
                              3 Einarbeitungskurse
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ py: '4px' }}>
                        <ListItemIcon>
                          <TaskAltRoundedIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span
                              style={{
                                fontSize: '1.25rem',
                                lineHeight: '1.5rem'
                              }}
                            >
                              13 Kurse zu seelischen, körperlichen und geistigen
                              Behinderungen
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ py: '4px' }}>
                        <ListItemIcon>
                          <TaskAltRoundedIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span
                              style={{
                                fontSize: '1.25rem',
                                lineHeight: '1.5rem'
                              }}
                            >
                              8+ Zusatzkurse
                            </span>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard
                icon={
                  <AssessmentRoundedIcon
                    color="primary"
                    sx={{ fontSize: '3rem' }}
                  />
                }
                title="Verwaltungsfunktionen"
                content={
                  <Box textAlign="start" sx={{ width: '100%' }}>
                    <List>
                      <ListItem sx={{ py: '4px' }}>
                        <ListItemIcon>
                          <TaskAltRoundedIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span
                              style={{
                                fontSize: '1.25rem',
                                lineHeight: '1.5rem'
                              }}
                            >
                              Zertifikate für bestandene Kurse
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ py: '4px' }}>
                        <ListItemIcon>
                          <TaskAltRoundedIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span
                              style={{
                                fontSize: '1.25rem',
                                lineHeight: '1.5rem'
                              }}
                            >
                              Statistiken zur Lernaktivität auf der Plattform
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{ py: '4px' }}>
                        <ListItemIcon>
                          <TaskAltRoundedIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span
                              style={{
                                fontSize: '1.25rem',
                                lineHeight: '1.5rem'
                              }}
                            >
                              Reporting über absolvierte Fortbildungen
                            </span>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoCard
                icon={
                  <img
                    src={imageDir('./message-ki.svg')}
                    width={48}
                    height={48}
                    alt="Diingu"
                    style={{
                      color: theme.palette.primary.main,
                      fill: theme.palette.primary.main
                    }}
                  />
                }
                title="KI Guide"
                content={
                  <Typography>
                    Rund um die Uhr verlässliche Handlungsempfehlungen für Ihre
                    Schulbegleitungen
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoCard
                icon={
                  <SupportAgentRoundedIcon
                    color="primary"
                    sx={{ fontSize: '3rem' }}
                  />
                }
                title="Kundensupport"
                content={
                  <Typography>
                    Für alle inhaltliche und technische Fragen zu Kursen und
                    Plattform
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack direction="column" spacing={8} textAlign="center">
            <Typography gutterBottom color="primary" variant="h3">
              Wie viele Personen sollen Diingu nutzen?
            </Typography>
            <Box sx={{ px: 2 }}>
              <DiinguSlider
                sx={{ mb: 4, mt: 2, maxWidth: '600px' }}
                value={licenseCount}
                onChange={(_, newValue) => setLicenseCount(newValue as number)}
                aria-labelledby="lizenz-slider"
                valueLabelDisplay="auto"
                step={10}
                min={0}
                scale={scale}
                marks={marks}
                max={300}
              />
              <div>
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  key={totalPrice}
                >
                  {totalPrice === 0 && (
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: '2.5rem !important',
                        lineHeight: '6rem !important',
                        my: 6
                      }}
                      color="primary"
                    >
                      Preis auf Anfrage
                    </Typography>
                  )}
                  {totalPrice !== 0 && (
                    <>
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: '6.5rem !important',
                          lineHeight: '7rem !important',
                          mt: 4
                        }}
                        color="primary"
                      >
                        {totalPrice},-
                      </Typography>
                      <Typography variant="h6" lineHeight="4rem">
                        <b>netto pro Person pro Jahr</b>
                      </Typography>
                    </>
                  )}
                </motion.div>
              </div>
            </Box>
            <Box>
              {getLicensePrice(licenseCount) !== 0 && (
                <Typography variant="body2" gutterBottom>
                  <b>
                    {formatter.format(
                      scale(licenseCount) * getLicensePrice(licenseCount)
                    )}
                  </b>
                  ,- für <b>{scale(licenseCount)}</b> Personen.
                </Typography>
              )}
              <Typography sx={{ px: 2, py: 1 }} variant="body2">
                Die erworbenen Lizenzen sind personengebunden und haben eine
                Laufzeit von 12 Monaten. Es können jederzeit zusätzliche
                Lizenzen erworben werden.
              </Typography>
            </Box>
            <AnimateButton>
              <Button
                disableElevation
                href="https://be2e51f7.sibforms.com/serve/MUIEAK6d0-Erb0CdL2oW6ItSH_oL3YtykWkI6GlsSB-0EU3ndk-M6gmUSvVoAPY8OjknsX9YQersX0QVyaIgElYVqD8f-YseEmYofCX59YVb_pw4j7EOf5JY-EnIn1HHvdEZhFxmtRpSw5_R8bDpLZTowXy9MB75MlV3-PxGfItN8Goh4QSF0xkiJOPAPHJgesMg9wse7HkTCAF0"
                target="_blank"
                variant="contained"
                size="large"
                sx={{
                  px: 2.75,
                  py: 1.5,
                  textTransform: 'none'
                }}
                endIcon={<PlaylistAddCheckCircleRoundedIcon />}
              >
                Termin vereinbaren
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LicensePricingBody;
