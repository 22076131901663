import { Container, Grid, useTheme, useMediaQuery } from '@mui/material';
import { gridSpacing } from 'store/constant';
import TeamCard from 'ui-component/cards/TeamCard';

import imgSahm from 'assets/images/landing/team/sahm.png';
import imgFelix from 'assets/images/landing/team/felix.png';
import imgFlo from 'assets/images/landing/team/flo.png';
import imgHans from 'assets/images/landing/team/hans.png';

import imgLattenkamp from 'assets/images/landing/team/lattenkamp.png';
import imgJohannes from 'assets/images/landing/team/johannes.png';

import imgLucas from 'assets/images/landing/team/lucas.png';
import imgHussain from 'assets/images/landing/team/hussain.jpg';
import imgDiana from 'assets/images/landing/team/diana.jpg';
import imgCarmilla from 'assets/images/landing/team/carmilla.jpg';

import imgDworschi from 'assets/images/landing/team/dworschak.png';
import imgGebhardt from 'assets/images/landing/team/gebhardt.png';

type TeamMember = {
  name: JSX.Element;
  title: string;
  text: JSX.Element;
  image: any;
  linkedIn?: string;
  email?: string;
  web?: string;
};

const teamMembers: TeamMember[] = [
  {
    // Row 1
    name: (
      <>
        Sahm <br /> Shojai
      </>
    ),
    title: 'Geschäftsführung',
    text: (
      <>
        Sahm gehört zum Gründer&shy;team. Aus sei&shy;ner Vision ent&shy;sprang
        die Idee von Diingu - seit&shy;dem kann er seine
        Be&shy;gei&shy;ster&shy;ung für Di&shy;gi&shy;ta&shy;li&shy;sie&shy;rung
        dazu nut&shy;zen, et&shy;was Be&shy;deut&shy;sames zu
        er&shy;schaf&shy;fen.
      </>
    ),
    image: imgSahm,
    email: 'sahm.shojai@diingu.de',
    linkedIn: 'https://www.linkedin.com/in/sahm-shojai-b0313315b/'
  },
  {
    name: (
      <>
        Felix <br /> Kolb
      </>
    ),
    title: 'Produktentwicklung',
    text: (
      <>
        Als Mitgründer und psycho&shy;lo&shy;gisch&shy;er
        Psy&shy;cho&shy;the&shy;ra&shy;peut ver&shy;kör&shy;pert Felix die
        fach&shy;liche Ex&shy;per&shy;ti&shy;se bei Diingu. Mit gro&shy;ßer
        Hin&shy;gabe hat er ein um&shy;fas&shy;sen&shy;des Cur&shy;ricu&shy;lum
        kon&shy;zi&shy;piert.
      </>
    ),
    image: imgFelix,
    email: 'felix.kolb@diingu.de',
    linkedIn: 'https://www.linkedin.com/in/felix-michael-kolb/'
  },
  {
    name: (
      <>
        Florian <br /> Kranz
      </>
    ),
    title: 'Softwareentwicklung',
    text: (
      <>
        Flo ist eben&shy;fal&shy;ls Mit&shy;grün&shy;der und hat das
        di&shy;gi&shy;tale Au&shy;di&shy;to&shy;ri&shy;um ins Le&shy;ben
        ge&shy;ru&shy;fen, wel&shy;ches sich durch Klar&shy;heit,
        Be&shy;nutz&shy;er&shy;freund&shy;lic&shy;hkeit &
        In&shy;no&shy;va&shy;tion aus&shy;zeich&shy;net.
      </>
    ),
    image: imgFlo,
    email: 'florian.kranz@diingu.de',
    linkedIn: 'https://www.linkedin.com/in/florian-kranz-2a98b6192/'
  },
  {
    name: (
      <>
        Hans <br /> Görlinger
      </>
    ),
    title: 'Marketing',
    text: (
      <>
        Hans leitet das Marketing von Diingu. Sein Ziel: Diingu auch über das
        Produkt hinaus als wichtige Stimme für Inklusion zu etablieren.
      </>
    ),
    image: imgHans,
    email: 'hans.goerlinger@diingu.de',
    linkedIn: 'https://www.linkedin.com/in/hans-g%C3%B6rlinger-bb0542274/'
  },
  // Row 2
  {
    name: (
      <>
        Lucas <br /> Iske
      </>
    ),
    title: 'Kundenbetreuung',
    text: (
      <>
        Mit einem Ohr für Kundenbedürfnisse, schafft Lucas schnell
        vertrauensvolle Beziehungen. Sein Engagement und seine
        Kommunikationsstärke machen ihn zum idealen Ansprechpartner.
      </>
    ),
    image: imgLucas,
    email: 'lucas.iske@diingu.de',
    linkedIn: 'https://www.linkedin.com/in/lucas-iske-460269272/'
  },
  {
    name: (
      <>
        Diana <br /> Saleh
      </>
    ),
    title: 'Investorenkommunikation',
    text: (
      <>
        Diana ist spezialisiert auf die Kommunikation mit
        Venture-Capital-Gebern. Mit ihrem Know-how unterstützt sie uns dabei,
        die Finanzierung für das Wachstum von Diingu zu sichern.
      </>
    ),
    image: imgDiana,
    linkedIn: 'https://www.linkedin.com/in/diana-saleh-b5889150/'
  },
  {
    name: (
      <>
        Prof. Dr. Markus <br /> Gebhardt
      </>
    ),
    title: 'Qualitätssicherung',
    text: (
      <>
        Prof. Dr. Geb&shy;hardt ist Pro&shy;fes&shy;sor für
        Son&shy;der&shy;pä&shy;da&shy;go&shy;gik mit dem
        För&shy;der&shy;schwer&shy;punkt Lernen ein&shy;schließlich inklusiver
        Pä&shy;da&shy;go&shy;gik an der LMU Mün&shy;chen.
        {/* Sein
        aka&shy;de&shy;misch&shy;er Hin&shy;ter&shy;grund ga&shy;ran&shy;tiert
        hö&shy;chste Stan&shy;dards und Ex&shy;per&shy;ti&shy;se. */}
      </>
    ),
    image: imgGebhardt,
    linkedIn: 'https://www.linkedin.com/in/markus-gebhardt-022a36229/',
    web:
      'https://www.edu.lmu.de/lbp/personen/lehrstuhlinhaber/gebhardt/index.html'
  },
  {
    name: (
      <>
        Prof. Dr. Wolfgang <br /> Dworschak
      </>
    ),
    title: 'Qualitätssicherung',
    text: (
      <>
        Prof. Dr. Dworschak lei&shy;tet den Lehr&shy;stuhl für
        Pä&shy;da&shy;go&shy;gik bei gei&shy;stig&shy;er
        Be&shy;hin&shy;der&shy;ung ein&shy;schließ&shy;lich
        in&shy;klu&shy;si&shy;ver Pä&shy;da&shy;go&shy;gik an der Universität
        Re&shy;gens&shy;burg.
        {/* und gilt als Ko&shy;ry&shy;phäe im Be&shy;reich
        In&shy;klu&shy;sion & Teil&shy;habe. */}
      </>
    ),
    image: imgDworschi,
    web:
      'https://www.uni-regensburg.de/humanwissenschaften/geistigbehindertenpaedagogik/prof-dr-wolfgang-dworschak/index.html',
    linkedIn:
      'https://www.linkedin.com/in/prof-dr-wolfgang-dworschak-69a635252/'
  },
  // Row 3
  {
    name: (
      <>
        Hussain <br /> Abbas
      </>
    ),
    title: 'Softwareentwicklung',
    text: (
      <>
        Hussain ist Experte für Backend-Entwicklung und hat bereits an einer
        Vielzahl von Projekten in diesem Bereich mitgewirkt. Parallel dazu
        absolviert er seinen Masterstudiengang in Frankfurt.
      </>
    ),
    image: imgHussain,
    linkedIn: 'https://www.linkedin.com/in/hussain-abbas-2001/'
  },
  {
    name: (
      <>
        Johannes <br /> Landhäußer
      </>
    ),
    title: 'StartUp-Advisor',
    text: (
      <>
        Johannes ist ein ehe&shy;ma&shy;li&shy;ger Sales Lead bei Google und
        be&shy;glei&shy;tet uns als Start&shy;Up-Ad&shy;vi&shy;sor mit
        stra&shy;te&shy;gisch&shy;en Ein&shy;sich&shy;ten und
        wert&shy;vol&shy;lem Bran&shy;chen-Know-how.
      </>
    ),
    image: imgJohannes,
    linkedIn: 'https://www.linkedin.com/in/johanneslandhaeusser/'
  },
  {
    name: (
      <>
        Carmilla <br /> Eder-Curreli
      </>
    ),
    title: 'Produktentwicklung & Qualitätssicherung',
    text: (
      <>
        Carmilla promoviert derzeit an der Universität Bamberg am Lehrstuhl für
        Sozialpädagogik. Bei Diingu unterstützt sie uns tatkräftig beim Aufbau
        und der Weiterentwicklung des Bereichs SPFH.
      </>
    ),
    image: imgCarmilla,
    linkedIn: 'https://www.linkedin.com/in/carmilla-eder-curreli-a7a218319/',
    web:
      'https://www.uni-bamberg.de/sozpaed/team/mitarbeiter-innen/carmilla-eder-curreli/'
  },
  {
    name: (
      <>
        Levin <br /> Lattenkamp
      </>
    ),
    title: 'Produktentwicklung & Qualitätssicherung',
    text: (
      <>
        Levin ist Arzt im Be&shy;reich Kin&shy;der- und
        Ju&shy;gend&shy;psy&shy;chia&shy;trie und
        -psy&shy;cho&shy;the&shy;ra&shy;pie. Mit seiner Ex&shy;per&shy;ti&shy;se
        ge&shy;währ&shy;lei&shy;stet er so&shy;wohl bei der
        In&shy;halts&shy;ent&shy;wick&shy;lung als auch der
        Qua&shy;li&shy;täts&shy;sich&shy;er&shy;ung hö&shy;chste
        Qua&shy;li&shy;tät.
      </>
    ),
    image: imgLattenkamp
  }
];

const Team = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container sx={{ mb: 16 }}>
      <Grid container spacing={isXs ? 2 : gridSpacing}>
        {teamMembers.map((member) => (
          <Grid item xs={6} sm={4} md={3}>
            <TeamCard
              name={member.name}
              title={member.title}
              imageUrl={member.image}
              overlayText={member.text}
              email={member.email}
              linkedIn={member.linkedIn}
              web={member.web}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Team;
