import { Grid, Typography, Button } from '@mui/material';

import { gridSpacing } from 'store/constant';
import { motion } from 'framer-motion';

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

const PressHeader = () => {
  return (
    <motion.div
      initial={{ opacity: 0, translateY: 550 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ type: 'spring', stiffness: 150, damping: 30 }}
    >
      <Grid
        container
        spacing={gridSpacing}
        justifyContent="space-between"
        sx={{ mt: { xs: 0, lg: '8vh' } }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            color="primary"
            sx={{
              maxWidth: '40ch'
            }}
          >
            Liebe Medienschaffende,
            <br /> herzlich willkommen auf unserem Presseportal!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              maxWidth: '50ch'
            }}
          >
            Wir freuen uns über Ihr Interesse an Diingu und unterstützen Sie
            gerne bei Ihrer journalistischen Arbeit! Für Presseanfragen und
            weitere Informationen steht Ihnen unser Pressekontakt jederzeit
            unter presse@diingu.de zur Verfügung.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              maxWidth: '50ch'
            }}
          >
            Folgende Dateien könnten Sie bei Ihrer Arbeit unterstützen:
          </Typography>
        </Grid>
        <Grid item>
          <motion.div
            initial={{ opacity: 0, translateY: 550 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 30,
              delay: 0.4
            }}
          >
            <Grid item container xs={12} spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  href="/Diingu-Einblick.pdf"
                  target="_blank"
                  endIcon={<DownloadRoundedIcon />}
                >
                  Einblick
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  href="/diingu-insight.pdf"
                  target="_blank"
                  color="secondary"
                  endIcon={<DownloadRoundedIcon />}
                >
                  Company Insight (ENG)
                </Button>
              </Grid>

              <Grid item>
                <Button
                  href="/diingu-pressefotos.zip"
                  variant="outlined"
                  endIcon={<DownloadRoundedIcon />}
                >
                  Fotos
                </Button>
              </Grid>

              <Grid item>
                <Button
                  href="/diingu-logos.zip"
                  variant="outlined"
                  endIcon={<DownloadRoundedIcon />}
                >
                  Logos
                </Button>
              </Grid>
            </Grid>
          </motion.div>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default PressHeader;
